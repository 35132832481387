.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.content {
  /* margin-top: 10px; */
  overflow-y: auto;
  padding: 24px;
  padding-top: 30px;
  min-height: 280px;
}

#login-container {
  background-image: url("../../../assets/images/login-bg.jpg");
  object-fit: cover;
  background-size: 100% 100%;
}
